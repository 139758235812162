export default function initUrlObfuscated() {
    const $UrlObfuscatedElements = document.querySelectorAll('.js-url-obf');

    $UrlObfuscatedElements.forEach($element => {
        new UrlObfuscated($element);
    });
}

/**
 *
 * @param {HTMLElement} $element HTML Element
 */
class UrlObfuscated {
    $element: Element;
    constructor($element: Element) {
        this.$element = $element;
        this.init();
    }

    init(): void {
        this.$element.addEventListener('click', () => {
            const dataHref = this.$element.getAttribute('data-href');
            if (dataHref) {
                const url = atob(dataHref);
                const target = this.$element.getAttribute('data-target');
                if (target === '_blank') {
                    window.open(url, '_blank');
                } else {
                    window.location.href = url;
                }
            }
        });
    }
}
